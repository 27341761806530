// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agorize-design-system-js": () => import("./../../../src/pages/agorize-design-system.js" /* webpackChunkName: "component---src-pages-agorize-design-system-js" */),
  "component---src-pages-agorize-product-js": () => import("./../../../src/pages/agorize-product.js" /* webpackChunkName: "component---src-pages-agorize-product-js" */),
  "component---src-pages-granular-js": () => import("./../../../src/pages/granular.js" /* webpackChunkName: "component---src-pages-granular-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-livestorm-access-room-2-js": () => import("./../../../src/pages/livestorm-access-room2.js" /* webpackChunkName: "component---src-pages-livestorm-access-room-2-js" */),
  "component---src-pages-livestorm-access-room-js": () => import("./../../../src/pages/livestorm-access-room.js" /* webpackChunkName: "component---src-pages-livestorm-access-room-js" */),
  "component---src-pages-motion-js": () => import("./../../../src/pages/motion.js" /* webpackChunkName: "component---src-pages-motion-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-photos-copy-js": () => import("./../../../src/pages/photos copy.js" /* webpackChunkName: "component---src-pages-photos-copy-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-sevenhugs-brand-js": () => import("./../../../src/pages/sevenhugs-brand.js" /* webpackChunkName: "component---src-pages-sevenhugs-brand-js" */),
  "component---src-pages-sevenhugs-product-js": () => import("./../../../src/pages/sevenhugs-product.js" /* webpackChunkName: "component---src-pages-sevenhugs-product-js" */),
  "component---src-pages-sideprojects-js": () => import("./../../../src/pages/sideprojects.js" /* webpackChunkName: "component---src-pages-sideprojects-js" */),
  "component---src-pages-sound-js": () => import("./../../../src/pages/sound.js" /* webpackChunkName: "component---src-pages-sound-js" */),
  "component---src-pages-sourceeasy-js": () => import("./../../../src/pages/sourceeasy.js" /* webpackChunkName: "component---src-pages-sourceeasy-js" */),
  "component---src-pages-triber-js": () => import("./../../../src/pages/triber.js" /* webpackChunkName: "component---src-pages-triber-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

